import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { RadioButton } from 'primereact/radiobutton';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useAddPage from 'hooks/useAddPage';
const ProductosAddPage = (props) => {
		const app = useApp();
	
	//form validation rules
	const validationSchema = yup.object().shape({
		codigo: yup.string().nullable().label("Código de Barras"),
		codigo_i: yup.string().nullable().label("Código Interno"),
		nombre: yup.string().nullable().label("Nombre"),
		id_tipo: yup.string().nullable().label("Tipo"),
		id_categoria: yup.string().nullable().label("Categoría"),
		id_corte: yup.string().nullable().label("Sub Categoría"),
		coste_s: yup.number().nullable().label("Coste S"),
		precio_s: yup.number().nullable().label("Precio S"),
		imponible: yup.array().nullable().label("Imponible"),
		comision_v: yup.number().nullable().label("Comision V"),
		comision_v2: yup.number().nullable().label("Comision V2"),
		comision_v3: yup.number().nullable().label("Comision V3"),
		minimo: yup.number().nullable().label("Minimo"),
		pertenece: yup.number().required().label("Pertenece"),
		estado: yup.string().nullable().label("Estado")
	});
	
	//form default values
	const formDefaultValues = {
		codigo: '', 
		codigo_i: '', 
		nombre: '', 
		id_tipo: '', 
		id_categoria: '', 
		id_corte: '', 
		coste_s: '', 
		precio_s: '', 
		imponible: [], 
		comision_v: '', 
		comision_v2: '', 
		comision_v3: '', 
		minimo: '', 
		pertenece: '', 
		estado: '', 
	}
	
	//page hook where logics resides
	const pageController =  useAddPage({ props, formDefaultValues, afterSubmit });
	
	// destructure and grab what the page needs
	const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;
	
	//event raised after form submit
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		resetForm();
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/productos`);
		}
	}
	
	// page loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	
	//page has loaded any required data and ready to render
	if(pageReady){
		return (
<main id="ProductosAddPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Agregar nuevo"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={(values, actions) =>submitForm(values)}>
                            {(formik) => 
                            <>
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Código de Barras 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="codigo"  onChange={formik.handleChange}  value={formik.values.codigo}   label="Código de Barras" type="text" placeholder="Escribir Código de Barras"        className={inputClassName(formik?.errors?.codigo)} />
                                                <ErrorMessage name="codigo" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Código Interno 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="codigo_i"  onChange={formik.handleChange}  value={formik.values.codigo_i}   label="Código Interno" type="text" placeholder="Escribir Código Interno"        className={inputClassName(formik?.errors?.codigo_i)} />
                                                <ErrorMessage name="codigo_i" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Nombre 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="nombre"  onChange={formik.handleChange}  value={formik.values.nombre}   label="Nombre" type="text" placeholder="Escribir Nombre"        className={inputClassName(formik?.errors?.nombre)} />
                                                <ErrorMessage name="nombre" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Tipo 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource   apiPath="components_data/id_tipo_option_list"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <Dropdown  name="id_tipo"     optionLabel="label" optionValue="value" value={formik.values.id_tipo} onChange={formik.handleChange} options={response} label="Tipo"  placeholder="Seleccione un valor" filter={true} className={inputClassName(formik?.errors?.id_tipo)}   />
                                                    <ErrorMessage name="id_tipo" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Categoría 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource   apiPath="components_data/id_categoria_option_list"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <Dropdown  name="id_categoria"     optionLabel="label" optionValue="value" value={formik.values.id_categoria} onChange={formik.handleChange} options={response} label="Categoría"  placeholder="Seleccione un valor" filter={true} className={inputClassName(formik?.errors?.id_categoria)}   />
                                                    <ErrorMessage name="id_categoria" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Sub Categoría 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource   apiPath="components_data/id_corte_option_list"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <Dropdown  name="id_corte"     optionLabel="label" optionValue="value" value={formik.values.id_corte} onChange={formik.handleChange} options={response} label="Sub Categoría"  placeholder="Seleccione un valor" filter={true} className={inputClassName(formik?.errors?.id_corte)}   />
                                                    <ErrorMessage name="id_corte" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Coste S 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="coste_s"  onChange={formik.handleChange}  value={formik.values.coste_s}   label="Coste S" type="number" placeholder="Escribir Coste S"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.coste_s)} />
                                                <ErrorMessage name="coste_s" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Precio S 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="precio_s"  onChange={formik.handleChange}  value={formik.values.precio_s}   label="Precio S" type="number" placeholder="Escribir Precio S"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.precio_s)} />
                                                <ErrorMessage name="precio_s" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Imponible 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                {
                                                app.menus.imponible.map((option) => {
                                                return (
                                                <div key={option.value} className="field-checkbox">
                                                    <Checkbox inputId={option.value} name="imponible" value={option.value} onChange={formik.handleChange} checked={formik.values.imponible.includes(option.value)} className={inputClassName(formik?.errors?.imponible, '')} />
                                                    <label htmlFor={option.value}>{option.label}</label>
                                                </div>
                                                )
                                                })
                                                }
                                                <ErrorMessage name="imponible" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Comision V 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="comision_v"  onChange={formik.handleChange}  value={formik.values.comision_v}   label="Comision V" type="number" placeholder="Escribir Comision V"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.comision_v)} />
                                                <ErrorMessage name="comision_v" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Comision V2 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="comision_v2"  onChange={formik.handleChange}  value={formik.values.comision_v2}   label="Comision V2" type="number" placeholder="Escribir Comision V2"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.comision_v2)} />
                                                <ErrorMessage name="comision_v2" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Comision V3 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="comision_v3"  onChange={formik.handleChange}  value={formik.values.comision_v3}   label="Comision V3" type="number" placeholder="Escribir Comision V3"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.comision_v3)} />
                                                <ErrorMessage name="comision_v3" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Minimo 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="minimo"  onChange={formik.handleChange}  value={formik.values.minimo}   label="Minimo" type="number" placeholder="Escribir Minimo"  min={0}  step="any"    className={inputClassName(formik?.errors?.minimo)} />
                                                <ErrorMessage name="minimo" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Pertenece *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="pertenece"  onChange={formik.handleChange}  value={formik.values.pertenece}   label="Pertenece" type="number" placeholder="Escribir Pertenece"  min={0}  step="any" disabled   className={inputClassName(formik?.errors?.pertenece)} />
                                                <ErrorMessage name="pertenece" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Estado 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource   apiPath="components_data/estado_option_list_3"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <div className="flex flex-wrap">
                                                        {
                                                        response.map((option) => {
                                                        return (
                                                        <div key={option.value} className="field-radiobutton  mx-3">
                                                            <RadioButton inputId={option.value} name="estado" value={option.value} onChange={formik.handleChange}  checked={formik.values.estado === option.value} className={inputClassName(formik?.errors?.estado, '')} />
                                                            <label htmlFor={option.value}>{option.label}</label>
                                                        </div>
                                                        )
                                                        })
                                                        }
                                                    </div>
                                                    <ErrorMessage name="estado" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label="Entregar" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            </>
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}

//page props and default values
ProductosAddPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'productos',
	apiPath: 'productos/add',
	routeName: 'productosadd',
	submitButtonLabel: "Entregar",
	formValidationError: "El formulario no es válido",
	formValidationMsg: "Por favor complete el formulario",
	msgTitle: "Crear registro",
	msgAfterSave: "Grabar agregado exitosamente",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default ProductosAddPage;
