import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useAddPage from 'hooks/useAddPage';
const AgendaAddPage = (props) => {
		const app = useApp();
	
	//form validation rules
	const validationSchema = yup.object().shape({
		id_cliente: yup.string().required().label("Cliente"),
		title: yup.string().nullable().label("Title"),
		description: yup.string().nullable().label("Description"),
		category: yup.number().nullable().label("Category"),
		creator: yup.string().nullable().label("Creator"),
		start_date: yup.string().required().label("Fecha Inicio"),
		start_time: yup.string().required().label("Hora de Inicio"),
		end_date: yup.string().required().label("Fecha Final"),
		end_time: yup.string().required().label("Hora Final"),
		event_color: yup.string().nullable().label("Event Color")
	});
	
	//form default values
	const formDefaultValues = {
		id_cliente: '', 
		title: '', 
		description: '', 
		category: '', 
		creator: '', 
		start_date: new Date(), 
		start_time: new Date(), 
		end_date: new Date(), 
		end_time: new Date(), 
		event_color: '', 
	}
	
	//page hook where logics resides
	const pageController =  useAddPage({ props, formDefaultValues, afterSubmit });
	
	// destructure and grab what the page needs
	const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;
	
	//event raised after form submit
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		resetForm();
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/agenda`);
		}
	}
	
	// page loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	
	//page has loaded any required data and ready to render
	if(pageReady){
		return (
<main id="AgendaAddPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Agregar nuevo"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={(values, actions) =>submitForm(values)}>
                            {(formik) => 
                            <>
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Cliente *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource   apiPath="components_data/id_cliente_option_list"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <Dropdown  name="id_cliente"     optionLabel="label" optionValue="value" value={formik.values.id_cliente} onChange={formik.handleChange} options={response} label="Cliente"  placeholder="Seleccione un valor" filter={true} className={inputClassName(formik?.errors?.id_cliente)}   />
                                                    <ErrorMessage name="id_cliente" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Title 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="title"  onChange={formik.handleChange}  value={formik.values.title}   label="Title" type="text" placeholder="Escribir Title"        className={inputClassName(formik?.errors?.title)} />
                                                <ErrorMessage name="title" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Description 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputTextarea name="description"  className={inputClassName(formik?.errors?.description)}   value={formik.values.description} placeholder="Escribir Description" onChange={formik.handleChange}   >
                                                </InputTextarea>
                                                <ErrorMessage name="description" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Category 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="category"  onChange={formik.handleChange}  value={formik.values.category}   label="Category" type="number" placeholder="Escribir Category"  min={0}  step="any"    className={inputClassName(formik?.errors?.category)} />
                                                <ErrorMessage name="category" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Creator 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="creator"  onChange={formik.handleChange}  value={formik.values.creator}   label="Creator" type="text" placeholder="Escribir Creator"        className={inputClassName(formik?.errors?.creator)} />
                                                <ErrorMessage name="creator" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Fecha Inicio *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="start_date" showButtonBar className={inputClassName(formik?.errors?.start_date)} dateFormat="yy-mm-dd" value={formik.values.start_date} onChange={formik.handleChange} showIcon        />
                                                <ErrorMessage name="start_date" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Hora de Inicio *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource   apiPath="components_data/start_time_option_list"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <Dropdown  name="start_time"     optionLabel="label" optionValue="value" value={formik.values.start_time} onChange={formik.handleChange} options={response} label="Hora de Inicio"  placeholder="Seleccione un valor"  className={inputClassName(formik?.errors?.start_time)}   />
                                                    <ErrorMessage name="start_time" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Fecha Final *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="end_date" showButtonBar className={inputClassName(formik?.errors?.end_date)} dateFormat="yy-mm-dd" value={formik.values.end_date} onChange={formik.handleChange} showIcon        />
                                                <ErrorMessage name="end_date" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Hora Final *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <DataSource   apiPath="components_data/start_time_option_list"  >
                                                    {
                                                    ({ response }) => 
                                                    <>
                                                    <Dropdown  name="end_time"     optionLabel="label" optionValue="value" value={formik.values.end_time} onChange={formik.handleChange} options={response} label="Hora Final"  placeholder="Seleccione un valor"  className={inputClassName(formik?.errors?.end_time)}   />
                                                    <ErrorMessage name="end_time" component="span" className="p-error" />
                                                    </>
                                                    }
                                                </DataSource>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Event Color 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="event_color"  onChange={formik.handleChange}  value={formik.values.event_color}   label="Event Color" type="text" placeholder="Escribir Event Color"        className={inputClassName(formik?.errors?.event_color)} />
                                                <ErrorMessage name="event_color" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label="Entregar" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            </>
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}

//page props and default values
AgendaAddPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'agenda',
	apiPath: 'agenda/add',
	routeName: 'agendaadd',
	submitButtonLabel: "Entregar",
	formValidationError: "El formulario no es válido",
	formValidationMsg: "Por favor complete el formulario",
	msgTitle: "Crear registro",
	msgAfterSave: "Grabar agregado exitosamente",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default AgendaAddPage;
