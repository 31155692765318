import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApi from 'hooks/useApi';
import useApp from 'hooks/useApp';
import useUtils from 'hooks/useUtils';

import useAddPage from 'hooks/useAddPage';
const RecomendacionesAddPage = (props) => {
		const app = useApp();
	const api = useApi();
	const utils = useUtils();
	
	//form validation rules
	const validationSchema = yup.object().shape({
		id_producto: yup.string().nullable().label("Producto o Servicio"),
		cantidad: yup.number().nullable().label("Cantidad"),
		precio_u: yup.number().nullable().label("Precio Unitario"),
		comentario: yup.string().nullable().label("Comentario"),
		fecha_cita: yup.string().nullable().label("Fecha de Cita"),
		id_consulta: yup.number().nullable().label("Id Consulta"),
		id_paciente: yup.string().nullable().label("Paciente"),
		fecha_r: yup.string().nullable().label("Fecha R")
	});
	
	//form default values
	const formDefaultValues = {
		id_producto: '', 
		cantidad: '', 
		precio_u: '', 
		comentario: '', 
		fecha_cita: new Date(), 
		id_consulta: '', 
		id_paciente: '', 
		fecha_r: utils.dateNow(), 
	}
	
	//page hook where logics resides
	const pageController =  useAddPage({ props, formDefaultValues, afterSubmit });
	
	// destructure and grab what the page needs
	const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;
	
	//event raised after form submit
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		resetForm();
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/recomendaciones`);
		}
	}
	
	//auto fill other inputs on id_producto change
	async function idProductoAutoFill(e, formik, index) {
		formik.handleChange(e);
		const val = e.target.value;
		const resp = await api.get(`components_data/recomendaciones_id_producto_autofill?value=${val}`);
		const data = resp.data[0];
		if(data){
formik.setFieldValue('precio_u', data.precio_s);
		}
	};
	
	// page loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	
	//page has loaded any required data and ready to render
	if(pageReady){
		return (
<main id="RecomendacionesAddPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Agregar Tratamiento"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={(values, actions) =>submitForm(values)}>
                            {(formik) => 
                            <>
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        Producto o Servicio 
                                        <div> 
                                            <DataSource   apiPath="components_data/id_producto_option_list"  >
                                                {
                                                ({ response }) => 
                                                <>
                                                <Dropdown  name="id_producto"     optionLabel="label" optionValue="value" value={formik.values.id_producto} onChange={(e)=>idProductoAutoFill(e, formik)} options={response} label="Producto o Servicio"  placeholder="Seleccione un valor" filter={true} className={inputClassName(formik?.errors?.id_producto)}   />
                                                <ErrorMessage name="id_producto" component="span" className="p-error" />
                                                </>
                                                }
                                            </DataSource>
                                        </div>
                                    </div>
                                    <div className="md:col-6 col-12">
                                        Cantidad 
                                        <div> 
                                            <InputText name="cantidad"  onChange={formik.handleChange}  value={formik.values.cantidad}   label="Cantidad" type="number" placeholder="Escribir Cantidad"  min={0}  step="any"    className={inputClassName(formik?.errors?.cantidad)} />
                                            <ErrorMessage name="cantidad" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-6 col-12">
                                        Precio Unitario 
                                        <div> 
                                            <InputText name="precio_u"  onChange={formik.handleChange}  value={formik.values.precio_u}   label="Precio Unitario" type="number" placeholder="Escribir Precio Unitario"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.precio_u)} />
                                            <ErrorMessage name="precio_u" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-12 col-12">
                                        Comentario 
                                        <div> 
                                            <InputTextarea name="comentario"  className={inputClassName(formik?.errors?.comentario)}   value={formik.values.comentario} placeholder="Escribir Comentario" onChange={formik.handleChange}   >
                                            </InputTextarea>
                                            <ErrorMessage name="comentario" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-6 col-12">
                                        Fecha de Cita 
                                        <div> 
                                            <Calendar name="fecha_cita" showButtonBar className={inputClassName(formik?.errors?.fecha_cita)} dateFormat="yy-mm-dd" value={formik.values.fecha_cita} onChange={formik.handleChange} showIcon        />
                                            <ErrorMessage name="fecha_cita" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-6 col-12">
                                        Id Consulta 
                                        <div> 
                                            <InputText name="id_consulta"  onChange={formik.handleChange}  value={formik.values.id_consulta}   label="Id Consulta" type="number" placeholder="Escribir Id Consulta"  min={0}  step="any" disabled   className={inputClassName(formik?.errors?.id_consulta)} />
                                            <ErrorMessage name="id_consulta" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        Paciente 
                                        <div> 
                                            <DataSource   apiPath="components_data/id_cliente_option_list"  >
                                                {
                                                ({ response }) => 
                                                <>
                                                <Dropdown  name="id_paciente"     optionLabel="label" optionValue="value" value={formik.values.id_paciente} onChange={formik.handleChange} options={response} label="Paciente"  placeholder="Seleccione un valor" filter={true} className={inputClassName(formik?.errors?.id_paciente)}   />
                                                <ErrorMessage name="id_paciente" component="span" className="p-error" />
                                                </>
                                                }
                                            </DataSource>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        Fecha R 
                                        <div> 
                                            <InputText name="fecha_r"  onChange={formik.handleChange}  value={formik.values.fecha_r}   label="Fecha R" type="text" placeholder="Escribir Fecha R"     disabled   className={inputClassName(formik?.errors?.fecha_r)} />
                                            <ErrorMessage name="fecha_r" component="span" className="p-error" />
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label="Entregar" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            </>
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}

//page props and default values
RecomendacionesAddPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'recomendaciones',
	apiPath: 'recomendaciones/add',
	routeName: 'recomendacionesadd',
	submitButtonLabel: "Entregar",
	formValidationError: "El formulario no es válido",
	formValidationMsg: "Por favor complete el formulario",
	msgTitle: "Crear registro",
	msgAfterSave: "Grabar agregado exitosamente",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default RecomendacionesAddPage;
