import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Menubar } from 'primereact/menubar';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import ClientesViewPage from 'pages/clientes/View';
import ProductosViewPage from 'pages/productos/View';
import RecomendacionesEditPage from 'pages/recomendaciones/Edit';
import useApp from 'hooks/useApp';
import useAuth from 'hooks/useAuth';
import useUtils from 'hooks/useUtils';

import useViewPage from 'hooks/useViewPage';
const RecomendacionesViewPage = (props) => {
		const auth = useAuth();
	const app = useApp();
	const utils = useUtils();
	const pageController = useViewPage(props);
	const { item, pageReady, loading, apiRequestError, deleteItem } = pageController;
	function ActionButton(data){
		const items = [
		{
			label: "Edit",
			command: (event) => { app.openPageDialog(<RecomendacionesEditPage isSubPage apiPath={`/recomendaciones/edit/${data.id}`} />, {closeBtn: true }) },
			icon: "pi pi-pencil",
			visible: () => auth.canView('recomendaciones/edit')
		},
		{
			label: "Delete",
			command: (event) => { deleteItem(data.id) },
			icon: "pi pi-trash",
			visible: () => auth.canView('recomendaciones/delete')
		}
	]
	.filter((item) => {
		if(item.visible){
			return item.visible()
		}
		return true;
	});
		return (<Menubar className="p-0 " model={items} />);
	}
	function PageFooter() {
		if (props.showFooter) {
			return (
				<div className="flex justify-content-between">
	<div className="flex justify-content-start">
	{ActionButton(item)}
	</div>
				</div>
			);
		}
	}
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	if(pageReady){
		return (
			<div>
<main id="RecomendacionesViewPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Ver"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
                <PageFooter />
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="col comp-grid" >
                    <div >
                        {/*PageComponentStart*/}
                        <div className="mb-3 ">
                            <div className="grid align-items-center">
                                <div className="col-fixed">
                                    <Avatar size="large" icon="pi pi-box" />
                                    </div>
                                    <div className="col">
                                        <div className="text-400 font-medium mb-1">Producto o Servicio</div>
                                        <div className="font-bold">{item.id_producto && <Button className="p-button-text" icon="pi pi-users" label={item.productos_nombre} onClick={() => app.openPageDialog(<ProductosViewPage isSubPage apiPath={`/productos/view/${item.id_producto}`} />, {closeBtn: true, width: '400px' })} /> }</div>
                                    </div>
                                </div>
                                <hr />
                                <div className="grid align-items-center">
                                    <div className="col">
                                        <div className="text-400 font-medium mb-1">Cantidad</div>
                                        <div className="font-bold">{ item.cantidad }</div>
                                    </div>
                                </div>
                                <hr />
                                <div className="grid align-items-center">
                                    <div className="col">
                                        <div className="text-400 font-medium mb-1">Precio Unitario</div>
                                        <div className="font-bold">{ item.precio_u }</div>
                                    </div>
                                </div>
                                <hr />
                                <div className="grid align-items-center">
                                    <div className="col">
                                        <div className="text-400 font-medium mb-1">Comentario</div>
                                        <div className="font-bold">{ item.comentario }</div>
                                    </div>
                                </div>
                                <hr />
                                <div className="grid align-items-center">
                                    <div className="col">
                                        <div className="text-400 font-medium mb-1">Fecha Cita</div>
                                        <div className="font-bold">{ item.fecha_cita }</div>
                                    </div>
                                </div>
                                <hr />
                                <div className="grid align-items-center">
                                    <div className="col">
                                        <div className="text-400 font-medium mb-1">Paciente</div>
                                        <div className="font-bold">{item.id_paciente && <Button className="p-button-text" icon="pi pi-users" label={item.clientes_nombre_l} onClick={() => app.openPageDialog(<ClientesViewPage isSubPage apiPath={`/clientes/view/${item.id_paciente}`} />, {closeBtn: true, width: '400px' })} /> }</div>
                                    </div>
                                </div>
                                <hr />
                                <div className="grid align-items-center">
                                    <div className="col">
                                        <div className="text-400 font-medium mb-1">Id</div>
                                        <div className="font-bold">{ item.id }</div>
                                    </div>
                                </div>
                                <hr />
                                <div className="grid align-items-center">
                                    <div className="col">
                                        <div className="text-400 font-medium mb-1">Id Consulta</div>
                                        <div className="font-bold">{ item.id_consulta }</div>
                                    </div>
                                </div>
                                <hr />
                                <div className="grid align-items-center">
                                    <div className="col">
                                        <div className="text-400 font-medium mb-1">Fecha Registro</div>
                                        <div className="font-bold">{utils.relativeDate( item.fecha_r )}</div>
                                    </div>
                                </div>
                                <hr />
                            </div>
                            {/*PageComponentEnd*/}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
			</div>
		);
	}
}
RecomendacionesViewPage.defaultProps = {
	id: null,
	primaryKey: 'id',
	pageName: 'recomendaciones',
	apiPath: 'recomendaciones/view',
	routeName: 'recomendacionesview',
	msgBeforeDelete: "¿Seguro que quieres borrar este registro?",
	msgTitle: "Eliminar el registro",
	msgAfterDelete: "Grabar eliminado con éxito",
	showHeader: true,
	showFooter: true,
	exportButton: true,
	isSubPage: false,
}
export default RecomendacionesViewPage;
