import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useAddPage from 'hooks/useAddPage';
const VentasdetalleAddPage = (props) => {
		const app = useApp();
	
	//form validation rules
	const validationSchema = yup.object().shape({
		id_venta: yup.number().required().label("Id Venta"),
		id_vendedor: yup.number().nullable().label("Id Vendedor"),
		id_producto: yup.number().nullable().label("Id Producto"),
		id_inventario: yup.number().nullable().label("Id Inventario"),
		id_precio: yup.number().nullable().label("Id Precio"),
		id_present: yup.number().nullable().label("Id Present"),
		cant_venta: yup.number().nullable().label("Cant Venta"),
		cant_present: yup.number().nullable().label("Cant Present"),
		cant_total: yup.number().nullable().label("Cant Total"),
		precio_u: yup.number().nullable().label("Precio U"),
		imponible: yup.number().nullable().label("Imponible"),
		impuesto_t: yup.number().nullable().label("Impuesto T"),
		descuento: yup.number().nullable().label("Descuento"),
		descuento_t: yup.number().nullable().label("Descuento T"),
		total_linea: yup.number().nullable().label("Total Linea"),
		fecha: yup.string().nullable().label("Fecha"),
		costo_u: yup.number().nullable().label("Costo U"),
		costo: yup.number().nullable().label("Costo"),
		ganancia: yup.number().nullable().label("Ganancia"),
		comision: yup.number().nullable().label("Comision"),
		comision_t: yup.number().nullable().label("Comision T"),
		check_ganancia: yup.number().nullable().label("Check Ganancia"),
		aplicado: yup.number().nullable().label("Aplicado"),
		anulada: yup.number().nullable().label("Anulada")
	});
	
	//form default values
	const formDefaultValues = {
		id_venta: '', 
		id_vendedor: '', 
		id_producto: '', 
		id_inventario: '', 
		id_precio: '', 
		id_present: '', 
		cant_venta: '', 
		cant_present: '', 
		cant_total: '', 
		precio_u: '', 
		imponible: '', 
		impuesto_t: '', 
		descuento: '', 
		descuento_t: '', 
		total_linea: '', 
		fecha: new Date(), 
		costo_u: '', 
		costo: '', 
		ganancia: '', 
		comision: '', 
		comision_t: '', 
		check_ganancia: '', 
		aplicado: '', 
		anulada: '', 
	}
	
	//page hook where logics resides
	const pageController =  useAddPage({ props, formDefaultValues, afterSubmit });
	
	// destructure and grab what the page needs
	const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;
	
	//event raised after form submit
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		resetForm();
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/ventasdetalle`);
		}
	}
	
	// page loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	
	//page has loaded any required data and ready to render
	if(pageReady){
		return (
<main id="VentasdetalleAddPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Agregar nuevo"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={(values, actions) =>submitForm(values)}>
                            {(formik) => 
                            <>
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Id Venta *
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="id_venta"  onChange={formik.handleChange}  value={formik.values.id_venta}   label="Id Venta" type="number" placeholder="Escribir Id Venta"  min={0}  step="any"    className={inputClassName(formik?.errors?.id_venta)} />
                                                <ErrorMessage name="id_venta" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Id Vendedor 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="id_vendedor"  onChange={formik.handleChange}  value={formik.values.id_vendedor}   label="Id Vendedor" type="number" placeholder="Escribir Id Vendedor"  min={0}  step="any"    className={inputClassName(formik?.errors?.id_vendedor)} />
                                                <ErrorMessage name="id_vendedor" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Id Producto 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="id_producto"  onChange={formik.handleChange}  value={formik.values.id_producto}   label="Id Producto" type="number" placeholder="Escribir Id Producto"  min={0}  step="any"    className={inputClassName(formik?.errors?.id_producto)} />
                                                <ErrorMessage name="id_producto" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Id Inventario 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="id_inventario"  onChange={formik.handleChange}  value={formik.values.id_inventario}   label="Id Inventario" type="number" placeholder="Escribir Id Inventario"  min={0}  step="any"    className={inputClassName(formik?.errors?.id_inventario)} />
                                                <ErrorMessage name="id_inventario" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Id Precio 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="id_precio"  onChange={formik.handleChange}  value={formik.values.id_precio}   label="Id Precio" type="number" placeholder="Escribir Id Precio"  min={0}  step="any"    className={inputClassName(formik?.errors?.id_precio)} />
                                                <ErrorMessage name="id_precio" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Id Present 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="id_present"  onChange={formik.handleChange}  value={formik.values.id_present}   label="Id Present" type="number" placeholder="Escribir Id Present"  min={0}  step="any"    className={inputClassName(formik?.errors?.id_present)} />
                                                <ErrorMessage name="id_present" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Cant Venta 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="cant_venta"  onChange={formik.handleChange}  value={formik.values.cant_venta}   label="Cant Venta" type="number" placeholder="Escribir Cant Venta"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.cant_venta)} />
                                                <ErrorMessage name="cant_venta" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Cant Present 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="cant_present"  onChange={formik.handleChange}  value={formik.values.cant_present}   label="Cant Present" type="number" placeholder="Escribir Cant Present"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.cant_present)} />
                                                <ErrorMessage name="cant_present" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Cant Total 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="cant_total"  onChange={formik.handleChange}  value={formik.values.cant_total}   label="Cant Total" type="number" placeholder="Escribir Cant Total"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.cant_total)} />
                                                <ErrorMessage name="cant_total" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Precio U 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="precio_u"  onChange={formik.handleChange}  value={formik.values.precio_u}   label="Precio U" type="number" placeholder="Escribir Precio U"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.precio_u)} />
                                                <ErrorMessage name="precio_u" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Imponible 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="imponible"  onChange={formik.handleChange}  value={formik.values.imponible}   label="Imponible" type="number" placeholder="Escribir Imponible"  min={0}  step="any"    className={inputClassName(formik?.errors?.imponible)} />
                                                <ErrorMessage name="imponible" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Impuesto T 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="impuesto_t"  onChange={formik.handleChange}  value={formik.values.impuesto_t}   label="Impuesto T" type="number" placeholder="Escribir Impuesto T"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.impuesto_t)} />
                                                <ErrorMessage name="impuesto_t" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Descuento 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="descuento"  onChange={formik.handleChange}  value={formik.values.descuento}   label="Descuento" type="number" placeholder="Escribir Descuento"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.descuento)} />
                                                <ErrorMessage name="descuento" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Descuento T 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="descuento_t"  onChange={formik.handleChange}  value={formik.values.descuento_t}   label="Descuento T" type="number" placeholder="Escribir Descuento T"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.descuento_t)} />
                                                <ErrorMessage name="descuento_t" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Total Linea 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="total_linea"  onChange={formik.handleChange}  value={formik.values.total_linea}   label="Total Linea" type="number" placeholder="Escribir Total Linea"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.total_linea)} />
                                                <ErrorMessage name="total_linea" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Fecha 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <Calendar name="fecha" value={formik.values.fecha} onChange={formik.handleChange} showButtonBar showTime dateFormat="yy-mm-dd" hourFormat="24"showIcon className={inputClassName(formik?.errors?.fecha)}        />
                                                <ErrorMessage name="fecha" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Costo U 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="costo_u"  onChange={formik.handleChange}  value={formik.values.costo_u}   label="Costo U" type="number" placeholder="Escribir Costo U"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.costo_u)} />
                                                <ErrorMessage name="costo_u" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Costo 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="costo"  onChange={formik.handleChange}  value={formik.values.costo}   label="Costo" type="number" placeholder="Escribir Costo"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.costo)} />
                                                <ErrorMessage name="costo" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Ganancia 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="ganancia"  onChange={formik.handleChange}  value={formik.values.ganancia}   label="Ganancia" type="number" placeholder="Escribir Ganancia"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.ganancia)} />
                                                <ErrorMessage name="ganancia" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Comision 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="comision"  onChange={formik.handleChange}  value={formik.values.comision}   label="Comision" type="number" placeholder="Escribir Comision"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.comision)} />
                                                <ErrorMessage name="comision" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Comision T 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="comision_t"  onChange={formik.handleChange}  value={formik.values.comision_t}   label="Comision T" type="number" placeholder="Escribir Comision T"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.comision_t)} />
                                                <ErrorMessage name="comision_t" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Check Ganancia 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="check_ganancia"  onChange={formik.handleChange}  value={formik.values.check_ganancia}   label="Check Ganancia" type="number" placeholder="Escribir Check Ganancia"  min={0}  step="any"    className={inputClassName(formik?.errors?.check_ganancia)} />
                                                <ErrorMessage name="check_ganancia" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Aplicado 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="aplicado"  onChange={formik.handleChange}  value={formik.values.aplicado}   label="Aplicado" type="number" placeholder="Escribir Aplicado"  min={0}  step="any"    className={inputClassName(formik?.errors?.aplicado)} />
                                                <ErrorMessage name="aplicado" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Anulada 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="anulada"  onChange={formik.handleChange}  value={formik.values.anulada}   label="Anulada" type="number" placeholder="Escribir Anulada"  min={0}  step="any"    className={inputClassName(formik?.errors?.anulada)} />
                                                <ErrorMessage name="anulada" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label="Entregar" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            </>
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}

//page props and default values
VentasdetalleAddPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'ventasdetalle',
	apiPath: 'ventasdetalle/add',
	routeName: 'ventasdetalleadd',
	submitButtonLabel: "Entregar",
	formValidationError: "El formulario no es válido",
	formValidationMsg: "Por favor complete el formulario",
	msgTitle: "Crear registro",
	msgAfterSave: "Grabar agregado exitosamente",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default VentasdetalleAddPage;
