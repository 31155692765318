/**
 * @Category React Hook function
 * Provide single source to manage application static menus items
 * 
**/


export default function useMenus() {
    
    
    return {
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Home",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/agenda",
    "label": "Agenda",
    "icon": "pi pi-calendar",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/clientes",
    "label": "Clientes",
    "icon": "pi pi-users",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/consultas",
    "label": "Consultas",
    "icon": "pi pi-book",
    "iconcolor": "",
    "target": "_parent",
  },
  {
    "to": "/productos",
    "label": "Productos",
    "icon": "pi pi-box",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/ventas",
    "label": "Ventas",
    "icon": "pi pi-cart-plus",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "",
    "label": "Ameniades",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/",
        "label": "Clientes Tipo",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
      },
      {
        "to": "/consultasareas",
        "label": "Consultas Areas",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
      },
      {
        "to": "/alteraciones",
        "label": "Alteraciones",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
      }
    ]
  },
  {
    "to": "/agendahoras",
    "label": "Agenda Horas",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/proveedores",
    "label": "Proveedores",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/vendedores",
    "label": "Vendedores",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/ventasdetalle",
    "label": "Ventas Detalle",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/transacciones",
    "label": "Transacciones",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/auusuarios",
    "label": "Au Usuarios",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/roles",
    "label": "Roles",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  },
  {
    "to": "/permissions",
    "label": "Permissions",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
  }
],
	aCredito: [    
{value: "1", label: "SI"},
	{value: "0", label: "NO"}
    ],
	diasCredito: [    
{value: "15", label: "15 Días"},
	{value: "30", label: "30 Días"},
	{value: "60", label: "60 Días"},
	{value: "90", label: "90 Días"},
	{value: "120", label: "120 Días"},
	{value: "0", label: "0 Días"}
    ],
	genero: [    
{value: "M", label: "Maculino"},
	{value: "F", label: "Femenino"}
    ],
	idAlteraciones: [    
{value: "0", label: "Ninguno"},
	{value: "1", label: "Asfictico"},
	{value: "2", label: "Deshidratado"},
	{value: "3", label: "Rosacea"},
	{value: "4", label: "Sensibles"},
	{value: "5", label: "Lunares"},
	{value: "6", label: "Pigmentado"},
	{value: "7", label: "Acneico"},
	{value: "8", label: "Telangectosias"},
	{value: "9", label: "Ojeras"},
	{value: "10", label: "Otros"},
	{value: "11", label: "Lineas de Expresion"},
	{value: "12", label: "Flacidez"},
	{value: "13", label: "Envejecimiento Cutaneo"},
	{value: "14", label: "Cicatrices"},
	{value: "15", label: "Estrias"},
	{value: "16", label: "Celulitis"},
	{value: "18", label: "Pigmentación"},
	{value: "19", label: "Adiposidad"},
	{value: "20", label: "Foliculitis"},
	{value: "21", label: "Fibrosis"},
	{value: "22", label: "Cicatrices"}
    ],
	imponible: [    
{value: "1", label: "SI"}
    ],
	tipoConsulta: [{"label":"Facial","value":"1"},{"label":"Corporal","value":"2"}],
        exportFormats: {
            print: {
                label: 'Print',
                icon: 'pi pi-print',
                type: 'print',
                ext: '',
            },
            pdf: {
                label: 'Pdf',
                icon: 'pi pi-file-pdf',
                type: 'pdf',
                ext: 'pdf',
            },
            excel: {
                label: 'Excel',
                icon: 'pi pi-file-excel',
                type: 'excel',
                ext: 'xlsx',
            },
            csv: {
                label: 'Csv',
                icon: 'pi pi-table',
                type: 'csv',
                ext: 'csv',
            },
        },
    }
}