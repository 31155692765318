import { Routes, Route, Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

import IndexLayout from 'layouts/IndexLayout';
import MainLayout from 'layouts/MainLayout';
import AuthRoutes from 'components/AuthRoutes';
import IndexPage from 'pages/index/IndexPage';
import AgendaList from 'pages/agenda/List';
import AgendaView from 'pages/agenda/View';
import AgendaAdd from 'pages/agenda/Add';
import AgendaEdit from 'pages/agenda/Edit';
import AgendahorasList from 'pages/agendahoras/List';
import AgendahorasView from 'pages/agendahoras/View';
import AgendahorasAdd from 'pages/agendahoras/Add';
import AgendahorasEdit from 'pages/agendahoras/Edit';
import AlteracionesList from 'pages/alteraciones/List';
import AlteracionesAdd from 'pages/alteraciones/Add';
import AlteracionesEdit from 'pages/alteraciones/Edit';
import AuusuariosList from 'pages/auusuarios/List';
import AuusuariosView from 'pages/auusuarios/View';
import AuusuariosAdd from 'pages/auusuarios/Add';
import AuusuariosEdit from 'pages/auusuarios/Edit';
import ClientesList from 'pages/clientes/List';
import ClientesView from 'pages/clientes/View';
import ClientesAdd from 'pages/clientes/Add';
import ClientesEdit from 'pages/clientes/Edit';
import ClientesestadoList from 'pages/clientesestado/List';
import ClientesestadoView from 'pages/clientesestado/View';
import ClientesestadoAdd from 'pages/clientesestado/Add';
import ClientesestadoEdit from 'pages/clientesestado/Edit';
import ClientestipoList from 'pages/clientestipo/List';
import ClientestipoView from 'pages/clientestipo/View';
import ClientestipoAdd from 'pages/clientestipo/Add';
import ClientestipoEdit from 'pages/clientestipo/Edit';
import ConsultasList from 'pages/consultas/List';
import ConsultasView from 'pages/consultas/View';
import ConsultasAdd from 'pages/consultas/Add';
import ConsultasEdit from 'pages/consultas/Edit';
import ConsultasConsultahistory from 'pages/consultas/ConsultaHistory';
import ConsultasareasList from 'pages/consultasareas/List';
import ConsultasareasView from 'pages/consultasareas/View';
import ConsultasareasAdd from 'pages/consultasareas/Add';
import ConsultasareasEdit from 'pages/consultasareas/Edit';
import ConsultasestadoList from 'pages/consultasestado/List';
import ConsultasestadoView from 'pages/consultasestado/View';
import ConsultasestadoAdd from 'pages/consultasestado/Add';
import ConsultasestadoEdit from 'pages/consultasestado/Edit';
import PermissionsList from 'pages/permissions/List';
import PermissionsView from 'pages/permissions/View';
import PermissionsAdd from 'pages/permissions/Add';
import PermissionsEdit from 'pages/permissions/Edit';
import ProductosList from 'pages/productos/List';
import ProductosView from 'pages/productos/View';
import ProductosAdd from 'pages/productos/Add';
import ProductosEdit from 'pages/productos/Edit';
import ProductosestadosList from 'pages/productosestados/List';
import ProductosestadosView from 'pages/productosestados/View';
import ProductosestadosAdd from 'pages/productosestados/Add';
import ProductosestadosEdit from 'pages/productosestados/Edit';
import ProveedoresList from 'pages/proveedores/List';
import ProveedoresView from 'pages/proveedores/View';
import ProveedoresAdd from 'pages/proveedores/Add';
import ProveedoresEdit from 'pages/proveedores/Edit';
import RecomendacionesList from 'pages/recomendaciones/List';
import RecomendacionesView from 'pages/recomendaciones/View';
import RecomendacionesAdd from 'pages/recomendaciones/Add';
import RecomendacionesEdit from 'pages/recomendaciones/Edit';
import RolesList from 'pages/roles/List';
import RolesView from 'pages/roles/View';
import RolesAdd from 'pages/roles/Add';
import RolesEdit from 'pages/roles/Edit';
import TransaccionesList from 'pages/transacciones/List';
import TransaccionesView from 'pages/transacciones/View';
import TransaccionesAdd from 'pages/transacciones/Add';
import TransaccionesEdit from 'pages/transacciones/Edit';
import VendedoresList from 'pages/vendedores/List';
import VendedoresView from 'pages/vendedores/View';
import VendedoresAdd from 'pages/vendedores/Add';
import VendedoresEdit from 'pages/vendedores/Edit';
import VentasList from 'pages/ventas/List';
import VentasView from 'pages/ventas/View';
import VentasAdd from 'pages/ventas/Add';
import VentasEdit from 'pages/ventas/Edit';
import VentasdetalleList from 'pages/ventasdetalle/List';
import VentasdetalleView from 'pages/ventasdetalle/View';
import VentasdetalleAdd from 'pages/ventasdetalle/Add';
import VentasdetalleEdit from 'pages/ventasdetalle/Edit';
import AccountPages from 'pages/account';
import HomePage from './pages/home/HomePage';
import IndexPages from './pages/index';
import ErrorPages from './pages/errors';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/styles/layout.scss';
const App = () => {
	const auth = useAuth();
	function DefaultPage(){
		if(!auth.isLoggedIn){
			return <IndexPage />
		}
		return <Navigate to="/home" replace />;
	}
	return (
		<Routes>
			<Route exact element={<AuthRoutes />}>
			<Route element={<MainLayout />}>
				<Route path="/home" element={<HomePage />} />
				

				{/* agenda pages routes */}
				<Route path="/agenda" element={<AgendaList />} />
				<Route path="/agenda/:fieldName/:fieldValue" element={<AgendaList />} />
				<Route path="/agenda/index/:fieldName/:fieldValue" element={<AgendaList />} />
				<Route path="/agenda/view/:pageid" element={<AgendaView />} />
				<Route path="/agenda/add" element={<AgendaAdd />} />
				<Route path="/agenda/edit/:pageid" element={<AgendaEdit />} />

				{/* agendahoras pages routes */}
				<Route path="/agendahoras" element={<AgendahorasList />} />
				<Route path="/agendahoras/:fieldName/:fieldValue" element={<AgendahorasList />} />
				<Route path="/agendahoras/index/:fieldName/:fieldValue" element={<AgendahorasList />} />
				<Route path="/agendahoras/view/:pageid" element={<AgendahorasView />} />
				<Route path="/agendahoras/add" element={<AgendahorasAdd />} />
				<Route path="/agendahoras/edit/:pageid" element={<AgendahorasEdit />} />

				{/* alteraciones pages routes */}
				<Route path="/alteraciones" element={<AlteracionesList />} />
				<Route path="/alteraciones/:fieldName/:fieldValue" element={<AlteracionesList />} />
				<Route path="/alteraciones/index/:fieldName/:fieldValue" element={<AlteracionesList />} />
				<Route path="/alteraciones/add" element={<AlteracionesAdd />} />
				<Route path="/alteraciones/edit/:pageid" element={<AlteracionesEdit />} />

				{/* auusuarios pages routes */}
				<Route path="/auusuarios" element={<AuusuariosList />} />
				<Route path="/auusuarios/:fieldName/:fieldValue" element={<AuusuariosList />} />
				<Route path="/auusuarios/index/:fieldName/:fieldValue" element={<AuusuariosList />} />
				<Route path="/auusuarios/view/:pageid" element={<AuusuariosView />} />
				<Route path="/auusuarios/add" element={<AuusuariosAdd />} />
				<Route path="/auusuarios/edit/:pageid" element={<AuusuariosEdit />} />

				{/* clientes pages routes */}
				<Route path="/clientes" element={<ClientesList />} />
				<Route path="/clientes/:fieldName/:fieldValue" element={<ClientesList />} />
				<Route path="/clientes/index/:fieldName/:fieldValue" element={<ClientesList />} />
				<Route path="/clientes/view/:pageid" element={<ClientesView />} />
				<Route path="/clientes/add" element={<ClientesAdd />} />
				<Route path="/clientes/edit/:pageid" element={<ClientesEdit />} />

				{/* clientesestado pages routes */}
				<Route path="/clientesestado" element={<ClientesestadoList />} />
				<Route path="/clientesestado/:fieldName/:fieldValue" element={<ClientesestadoList />} />
				<Route path="/clientesestado/index/:fieldName/:fieldValue" element={<ClientesestadoList />} />
				<Route path="/clientesestado/view/:pageid" element={<ClientesestadoView />} />
				<Route path="/clientesestado/add" element={<ClientesestadoAdd />} />
				<Route path="/clientesestado/edit/:pageid" element={<ClientesestadoEdit />} />

				{/* clientestipo pages routes */}
				<Route path="/clientestipo" element={<ClientestipoList />} />
				<Route path="/clientestipo/:fieldName/:fieldValue" element={<ClientestipoList />} />
				<Route path="/clientestipo/index/:fieldName/:fieldValue" element={<ClientestipoList />} />
				<Route path="/clientestipo/view/:pageid" element={<ClientestipoView />} />
				<Route path="/clientestipo/add" element={<ClientestipoAdd />} />
				<Route path="/clientestipo/edit/:pageid" element={<ClientestipoEdit />} />

				{/* consultas pages routes */}
				<Route path="/consultas" element={<ConsultasList />} />
				<Route path="/consultas/:fieldName/:fieldValue" element={<ConsultasList />} />
				<Route path="/consultas/index/:fieldName/:fieldValue" element={<ConsultasList />} />
				<Route path="/consultas/view/:pageid" element={<ConsultasView />} />
				<Route path="/consultas/add" element={<ConsultasAdd />} />
				<Route path="/consultas/edit/:pageid" element={<ConsultasEdit />} />
				<Route path="/consultas/consulta_history" element={<ConsultasConsultahistory />} />
				<Route path="/consultas/consulta_history/:fieldName/:fieldValue" element={<ConsultasConsultahistory />} />

				{/* consultasareas pages routes */}
				<Route path="/consultasareas" element={<ConsultasareasList />} />
				<Route path="/consultasareas/:fieldName/:fieldValue" element={<ConsultasareasList />} />
				<Route path="/consultasareas/index/:fieldName/:fieldValue" element={<ConsultasareasList />} />
				<Route path="/consultasareas/view/:pageid" element={<ConsultasareasView />} />
				<Route path="/consultasareas/add" element={<ConsultasareasAdd />} />
				<Route path="/consultasareas/edit/:pageid" element={<ConsultasareasEdit />} />

				{/* consultasestado pages routes */}
				<Route path="/consultasestado" element={<ConsultasestadoList />} />
				<Route path="/consultasestado/:fieldName/:fieldValue" element={<ConsultasestadoList />} />
				<Route path="/consultasestado/index/:fieldName/:fieldValue" element={<ConsultasestadoList />} />
				<Route path="/consultasestado/view/:pageid" element={<ConsultasestadoView />} />
				<Route path="/consultasestado/add" element={<ConsultasestadoAdd />} />
				<Route path="/consultasestado/edit/:pageid" element={<ConsultasestadoEdit />} />

				{/* permissions pages routes */}
				<Route path="/permissions" element={<PermissionsList />} />
				<Route path="/permissions/:fieldName/:fieldValue" element={<PermissionsList />} />
				<Route path="/permissions/index/:fieldName/:fieldValue" element={<PermissionsList />} />
				<Route path="/permissions/view/:pageid" element={<PermissionsView />} />
				<Route path="/permissions/add" element={<PermissionsAdd />} />
				<Route path="/permissions/edit/:pageid" element={<PermissionsEdit />} />

				{/* productos pages routes */}
				<Route path="/productos" element={<ProductosList />} />
				<Route path="/productos/:fieldName/:fieldValue" element={<ProductosList />} />
				<Route path="/productos/index/:fieldName/:fieldValue" element={<ProductosList />} />
				<Route path="/productos/view/:pageid" element={<ProductosView />} />
				<Route path="/productos/add" element={<ProductosAdd />} />
				<Route path="/productos/edit/:pageid" element={<ProductosEdit />} />

				{/* productosestados pages routes */}
				<Route path="/productosestados" element={<ProductosestadosList />} />
				<Route path="/productosestados/:fieldName/:fieldValue" element={<ProductosestadosList />} />
				<Route path="/productosestados/index/:fieldName/:fieldValue" element={<ProductosestadosList />} />
				<Route path="/productosestados/view/:pageid" element={<ProductosestadosView />} />
				<Route path="/productosestados/add" element={<ProductosestadosAdd />} />
				<Route path="/productosestados/edit/:pageid" element={<ProductosestadosEdit />} />

				{/* proveedores pages routes */}
				<Route path="/proveedores" element={<ProveedoresList />} />
				<Route path="/proveedores/:fieldName/:fieldValue" element={<ProveedoresList />} />
				<Route path="/proveedores/index/:fieldName/:fieldValue" element={<ProveedoresList />} />
				<Route path="/proveedores/view/:pageid" element={<ProveedoresView />} />
				<Route path="/proveedores/add" element={<ProveedoresAdd />} />
				<Route path="/proveedores/edit/:pageid" element={<ProveedoresEdit />} />

				{/* recomendaciones pages routes */}
				<Route path="/recomendaciones" element={<RecomendacionesList />} />
				<Route path="/recomendaciones/:fieldName/:fieldValue" element={<RecomendacionesList />} />
				<Route path="/recomendaciones/index/:fieldName/:fieldValue" element={<RecomendacionesList />} />
				<Route path="/recomendaciones/view/:pageid" element={<RecomendacionesView />} />
				<Route path="/recomendaciones/add" element={<RecomendacionesAdd />} />
				<Route path="/recomendaciones/edit/:pageid" element={<RecomendacionesEdit />} />

				{/* roles pages routes */}
				<Route path="/roles" element={<RolesList />} />
				<Route path="/roles/:fieldName/:fieldValue" element={<RolesList />} />
				<Route path="/roles/index/:fieldName/:fieldValue" element={<RolesList />} />
				<Route path="/roles/view/:pageid" element={<RolesView />} />
				<Route path="/roles/add" element={<RolesAdd />} />
				<Route path="/roles/edit/:pageid" element={<RolesEdit />} />

				{/* transacciones pages routes */}
				<Route path="/transacciones" element={<TransaccionesList />} />
				<Route path="/transacciones/:fieldName/:fieldValue" element={<TransaccionesList />} />
				<Route path="/transacciones/index/:fieldName/:fieldValue" element={<TransaccionesList />} />
				<Route path="/transacciones/view/:pageid" element={<TransaccionesView />} />
				<Route path="/transacciones/add" element={<TransaccionesAdd />} />
				<Route path="/transacciones/edit/:pageid" element={<TransaccionesEdit />} />

				{/* vendedores pages routes */}
				<Route path="/vendedores" element={<VendedoresList />} />
				<Route path="/vendedores/:fieldName/:fieldValue" element={<VendedoresList />} />
				<Route path="/vendedores/index/:fieldName/:fieldValue" element={<VendedoresList />} />
				<Route path="/vendedores/view/:pageid" element={<VendedoresView />} />
				<Route path="/vendedores/add" element={<VendedoresAdd />} />
				<Route path="/vendedores/edit/:pageid" element={<VendedoresEdit />} />

				{/* ventas pages routes */}
				<Route path="/ventas" element={<VentasList />} />
				<Route path="/ventas/:fieldName/:fieldValue" element={<VentasList />} />
				<Route path="/ventas/index/:fieldName/:fieldValue" element={<VentasList />} />
				<Route path="/ventas/view/:pageid" element={<VentasView />} />
				<Route path="/ventas/add" element={<VentasAdd />} />
				<Route path="/ventas/edit/:pageid" element={<VentasEdit />} />

				{/* ventasdetalle pages routes */}
				<Route path="/ventasdetalle" element={<VentasdetalleList />} />
				<Route path="/ventasdetalle/:fieldName/:fieldValue" element={<VentasdetalleList />} />
				<Route path="/ventasdetalle/index/:fieldName/:fieldValue" element={<VentasdetalleList />} />
				<Route path="/ventasdetalle/view/:pageid" element={<VentasdetalleView />} />
				<Route path="/ventasdetalle/add" element={<VentasdetalleAdd />} />
				<Route path="/ventasdetalle/edit/:pageid" element={<VentasdetalleEdit />} />
				<Route path="/account/*" element={<AccountPages />} />
			</Route>
			</Route>
			<Route exact element={<IndexLayout />}>
				<Route path="/" element={<DefaultPage />} />
				<Route path="/*" element={<IndexPages />} />
				<Route path="/error/*" element={<ErrorPages />} />
			</Route>
		</Routes>
	);
}
export default App;
