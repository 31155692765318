import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
import MasterDetailPages from './MasterDetailPages';
const VentasEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		id_cliente: yup.string().required().label("Id Cliente"),
		id_vendedor: yup.string().nullable().label("Id Vendedor"),
		tipo: yup.number().nullable().label("Tipo"),
		fecha: yup.string().nullable().label("Fecha"),
		fecha_v: yup.string().nullable().label("Fecha V"),
		credito: yup.number().nullable().label("Credito"),
		dias: yup.number().nullable().label("Dias"),
		subtotal: yup.number().nullable().label("Subtotal"),
		descuento: yup.number().nullable().label("Descuento"),
		impuesto: yup.number().nullable().label("Impuesto"),
		total: yup.number().nullable().label("Total"),
		obs: yup.string().nullable().label("Obs"),
		estado: yup.number().nullable().label("Estado")
	});
	// form default values
	const formDefaultValues = {
		id_cliente: "1", 
		id_vendedor: '', 
		tipo: '', 
		fecha: new Date(), 
		fecha_v: new Date(), 
		credito: '', 
		dias: '', 
		subtotal: '', 
		descuento: '', 
		impuesto: '', 
		total: '', 
		obs: '', 
		estado: '', 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, currentRecord, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/ventas`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="VentasEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Editar"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="col-12 sm:col-12 lg:col-12 md:col-12 comp-grid" >
                    <div >
                        <div className="grid ">
                            <div className="col-12 sm:col-12 lg:col-4 md:col-4">
                                <Formik
                                    initialValues={formData}
                                    validationSchema={validationSchema} 
                                    onSubmit={(values, actions) => {
                                    submitForm(values);
                                    }
                                    }
                                    >
                                    { (formik) => {
                                    return (
                                    <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                        <div className="grid">
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Id Cliente *
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <DataSource   apiPath="components_data/id_cliente_option_list"  >
                                                            {
                                                            ({ response }) => 
                                                            <>
                                                            <Dropdown  name="id_cliente"     optionLabel="label" optionValue="value" value={formik.values.id_cliente} onChange={formik.handleChange} options={response} label="Id Cliente"  placeholder="Seleccione un valor" filter={true} className={inputClassName(formik?.errors?.id_cliente)}   />
                                                            <ErrorMessage name="id_cliente" component="span" className="p-error" />
                                                            </>
                                                            }
                                                        </DataSource>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Id Vendedor 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <DataSource   apiPath="components_data/id_vendedor_option_list"  >
                                                            {
                                                            ({ response }) => 
                                                            <>
                                                            <Dropdown  name="id_vendedor"     optionLabel="label" optionValue="value" value={formik.values.id_vendedor} onChange={formik.handleChange} options={response} label="Id Vendedor"  placeholder="Seleccione un valor" filter={true} className={inputClassName(formik?.errors?.id_vendedor)}   />
                                                            <ErrorMessage name="id_vendedor" component="span" className="p-error" />
                                                            </>
                                                            }
                                                        </DataSource>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Tipo 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <InputText name="tipo"  onChange={formik.handleChange}  value={formik.values.tipo}   label="Tipo" type="number" placeholder="Escribir Tipo"  min={0}  step="any"    className={inputClassName(formik?.errors?.tipo)} />
                                                        <ErrorMessage name="tipo" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Fecha 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <Calendar name="fecha" value={formik.values.fecha} onChange={formik.handleChange} showButtonBar showTime dateFormat="yy-mm-dd" hourFormat="24"showIcon className={inputClassName(formik?.errors?.fecha)}        />
                                                        <ErrorMessage name="fecha" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Fecha V 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <Calendar name="fecha_v" showButtonBar className={inputClassName(formik?.errors?.fecha_v)} dateFormat="yy-mm-dd" value={formik.values.fecha_v} onChange={formik.handleChange} showIcon        />
                                                        <ErrorMessage name="fecha_v" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Credito 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <InputText name="credito"  onChange={formik.handleChange}  value={formik.values.credito}   label="Credito" type="number" placeholder="Escribir Credito"  min={0}  step="any"    className={inputClassName(formik?.errors?.credito)} />
                                                        <ErrorMessage name="credito" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Dias 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <InputText name="dias"  onChange={formik.handleChange}  value={formik.values.dias}   label="Dias" type="number" placeholder="Escribir Dias"  min={0}  step="any"    className={inputClassName(formik?.errors?.dias)} />
                                                        <ErrorMessage name="dias" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Subtotal 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <InputText name="subtotal"  onChange={formik.handleChange}  value={formik.values.subtotal}   label="Subtotal" type="number" placeholder="Escribir Subtotal"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.subtotal)} />
                                                        <ErrorMessage name="subtotal" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Descuento 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <InputText name="descuento"  onChange={formik.handleChange}  value={formik.values.descuento}   label="Descuento" type="number" placeholder="Escribir Descuento"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.descuento)} />
                                                        <ErrorMessage name="descuento" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Impuesto 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <InputText name="impuesto"  onChange={formik.handleChange}  value={formik.values.impuesto}   label="Impuesto" type="number" placeholder="Escribir Impuesto"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.impuesto)} />
                                                        <ErrorMessage name="impuesto" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Total 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <InputText name="total"  onChange={formik.handleChange}  value={formik.values.total}   label="Total" type="number" placeholder="Escribir Total"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.total)} />
                                                        <ErrorMessage name="total" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Obs 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <InputTextarea name="obs"  className={inputClassName(formik?.errors?.obs)}   value={formik.values.obs} placeholder="Escribir Obs" onChange={formik.handleChange}   >
                                                        </InputTextarea>
                                                        <ErrorMessage name="obs" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Estado 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <InputText name="estado"  onChange={formik.handleChange}  value={formik.values.estado}   label="Estado" type="number" placeholder="Escribir Estado"  min={0}  step="any"    className={inputClassName(formik?.errors?.estado)} />
                                                        <ErrorMessage name="estado" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        { props.showFooter && 
                                        <div className="text-center my-3">
                                            <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Actualizar" icon="pi pi-send" loading={saving} />
                                        </div>
                                        }
                                    </Form>
                                    );
                                    }
                                    }
                                    </Formik>
                                </div>
                                {
                                (currentRecord && !props.isSubPage) && 
                                <div className="col-12 sm:col-12 lg:col-8 md:col-8">
                                    <div className="card my-3">
                                        <MasterDetailPages masterRecord={currentRecord} scrollIntoView={false} />
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
VentasEditPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'ventas',
	apiPath: 'ventas/edit',
	routeName: 'ventasedit',
	submitButtonLabel: "Actualizar",
	formValidationError: "El formulario no es válido",
	formValidationMsg: "Por favor complete el formulario",
	msgTitle: "Actualizar registro",
	msgAfterSave: "Registro actualizado con éxito",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default VentasEditPage;
