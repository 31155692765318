import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { RadioButton } from 'primereact/radiobutton';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
import MasterDetailPages from './MasterDetailPages';
const ClientesEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		nombre_l: yup.string().nullable().label("Nombre Legal"),
		nombre_c: yup.string().nullable().label("Nombre Comercial"),
		ced: yup.string().nullable().label("Cédula o Ruc"),
		telefono: yup.string().nullable().label("Teléfono"),
		celular: yup.string().nullable().label("Celular"),
		correo: yup.string().nullable().label("Correo"),
		direccion: yup.string().nullable().label("Dirección"),
		tipo_cliente: yup.string().nullable().label("Tipo de Cliente"),
		tipo_piel: yup.string().nullable().label("Tipo Piel"),
		fecha_n: yup.string().nullable().label("Fecha de Nacimiento"),
		a_credito: yup.string().nullable().label("Aplica a Crédito"),
		dias_credito: yup.string().nullable().label("Dias Crédito"),
		limite_credito: yup.number().nullable().label("Limite Crédito"),
		alias: yup.string().nullable().label("Alias"),
		estado: yup.string().nullable().label("Estado"),
		genero: yup.string().nullable().label("Genero")
	});
	// form default values
	const formDefaultValues = {
		nombre_l: '', 
		nombre_c: '', 
		ced: '', 
		telefono: '', 
		celular: '', 
		correo: '', 
		direccion: '', 
		tipo_cliente: '', 
		tipo_piel: '', 
		fecha_n: new Date(), 
		a_credito: "0", 
		dias_credito: "0", 
		limite_credito: '', 
		alias: '', 
		estado: '', 
		genero: '', 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, currentRecord, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/clientes`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="ClientesEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container-fluid">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Editar"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container-fluid">
            <div className="grid ">
                <div className="col-12 sm:col-12 lg:col-12 md:col-12 comp-grid" >
                    <div >
                        <div className="grid ">
                            <div className="col sm:col-12 lg:col-6 md:col-4">
                                <Formik
                                    initialValues={formData}
                                    validationSchema={validationSchema} 
                                    onSubmit={(values, actions) => {
                                    submitForm(values);
                                    }
                                    }
                                    >
                                    { (formik) => {
                                    return (
                                    <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                        <div className="grid">
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Nombre Legal 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <InputText name="nombre_l"  onChange={formik.handleChange}  value={formik.values.nombre_l}   label="Nombre Legal" type="text" placeholder="Escribir Nombre Legal"        className={inputClassName(formik?.errors?.nombre_l)} />
                                                        <ErrorMessage name="nombre_l" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Nombre Comercial 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <InputText name="nombre_c"  onChange={formik.handleChange}  value={formik.values.nombre_c}   label="Nombre Comercial" type="text" placeholder="Escribir Nombre Comercial"        className={inputClassName(formik?.errors?.nombre_c)} />
                                                        <ErrorMessage name="nombre_c" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Cédula o Ruc 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <InputText name="ced"  onChange={formik.handleChange}  value={formik.values.ced}   label="Cédula o Ruc" type="text" placeholder="Escribir Cédula o Ruc"        className={inputClassName(formik?.errors?.ced)} />
                                                        <ErrorMessage name="ced" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Teléfono 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <InputText name="telefono"  onChange={formik.handleChange}  value={formik.values.telefono}   label="Teléfono" type="text" placeholder="Escribir Teléfono"        className={inputClassName(formik?.errors?.telefono)} />
                                                        <ErrorMessage name="telefono" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Celular 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <InputText name="celular"  onChange={formik.handleChange}  value={formik.values.celular}   label="Celular" type="text" placeholder="Escribir Celular"        className={inputClassName(formik?.errors?.celular)} />
                                                        <ErrorMessage name="celular" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Correo 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <InputTextarea name="correo"  className={inputClassName(formik?.errors?.correo)}   value={formik.values.correo} placeholder="Escribir Correo" onChange={formik.handleChange}   >
                                                        </InputTextarea>
                                                        <ErrorMessage name="correo" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Dirección 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <InputTextarea name="direccion"  className={inputClassName(formik?.errors?.direccion)}   value={formik.values.direccion} placeholder="Escribir Dirección" onChange={formik.handleChange}   >
                                                        </InputTextarea>
                                                        <ErrorMessage name="direccion" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Tipo de Cliente 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <DataSource   apiPath="components_data/tipo_cliente_option_list"  >
                                                            {
                                                            ({ response }) => 
                                                            <>
                                                            <Dropdown  name="tipo_cliente"     optionLabel="label" optionValue="value" value={formik.values.tipo_cliente} onChange={formik.handleChange} options={response} label="Tipo de Cliente"  placeholder="Seleccione un valor"  className={inputClassName(formik?.errors?.tipo_cliente)}   />
                                                            <ErrorMessage name="tipo_cliente" component="span" className="p-error" />
                                                            </>
                                                            }
                                                        </DataSource>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Tipo Piel 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <DataSource   apiPath="components_data/tipo_piel_option_list"  >
                                                            {
                                                            ({ response }) => 
                                                            <>
                                                            <div className="flex flex-wrap">
                                                                {
                                                                response.map((option) => {
                                                                return (
                                                                <div key={option.value} className="field-radiobutton  mx-3">
                                                                    <RadioButton inputId={option.value} name="tipo_piel" value={option.value} onChange={formik.handleChange}  checked={formik.values.tipo_piel === option.value} className={inputClassName(formik?.errors?.tipo_piel, '')} />
                                                                    <label htmlFor={option.value}>{option.label}</label>
                                                                </div>
                                                                )
                                                                })
                                                                }
                                                            </div>
                                                            <ErrorMessage name="tipo_piel" component="span" className="p-error" />
                                                            </>
                                                            }
                                                        </DataSource>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Fecha de Nacimiento 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <Calendar name="fecha_n" showButtonBar className={inputClassName(formik?.errors?.fecha_n)} dateFormat="yy-mm-dd" value={formik.values.fecha_n} onChange={formik.handleChange} showIcon        />
                                                        <ErrorMessage name="fecha_n" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Aplica a Crédito 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <div className="flex flex-wrap">
                                                            {
                                                            app.menus.aCredito.map((option) => {
                                                            return (
                                                            <div key={option.value} className="field-radiobutton  mx-3">
                                                                <RadioButton inputId={option.value} name="a_credito" value={option.value} onChange={formik.handleChange}  checked={formik.values.a_credito === option.value} className={inputClassName(formik?.errors?.a_credito, '')} />
                                                                <label htmlFor={option.value}>{option.label}</label>
                                                            </div>
                                                            )
                                                            })
                                                            }
                                                        </div>
                                                        <ErrorMessage name="a_credito" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Dias Crédito 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <div className="flex flex-wrap">
                                                            {
                                                            app.menus.diasCredito.map((option) => {
                                                            return (
                                                            <div key={option.value} className="field-radiobutton  mx-3">
                                                                <RadioButton inputId={option.value} name="dias_credito" value={option.value} onChange={formik.handleChange}  checked={formik.values.dias_credito === option.value} className={inputClassName(formik?.errors?.dias_credito, '')} />
                                                                <label htmlFor={option.value}>{option.label}</label>
                                                            </div>
                                                            )
                                                            })
                                                            }
                                                        </div>
                                                        <ErrorMessage name="dias_credito" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Limite Crédito 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <InputText name="limite_credito"  onChange={formik.handleChange}  value={formik.values.limite_credito}   label="Limite Crédito" type="number" placeholder="Escribir Limite Crédito"  min={0}  step={0.1}    className={inputClassName(formik?.errors?.limite_credito)} />
                                                        <ErrorMessage name="limite_credito" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Alias 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <InputText name="alias"  onChange={formik.handleChange}  value={formik.values.alias}   label="Alias" type="text" placeholder="Escribir Alias"        className={inputClassName(formik?.errors?.alias)} />
                                                        <ErrorMessage name="alias" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Estado 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <DataSource   apiPath="components_data/estado_option_list"  >
                                                            {
                                                            ({ response }) => 
                                                            <>
                                                            <div className="flex flex-wrap">
                                                                {
                                                                response.map((option) => {
                                                                return (
                                                                <div key={option.value} className="field-radiobutton  mx-3">
                                                                    <RadioButton inputId={option.value} name="estado" value={option.value} onChange={formik.handleChange}  checked={formik.values.estado === option.value} className={inputClassName(formik?.errors?.estado, '')} />
                                                                    <label htmlFor={option.value}>{option.label}</label>
                                                                </div>
                                                                )
                                                                })
                                                                }
                                                            </div>
                                                            <ErrorMessage name="estado" component="span" className="p-error" />
                                                            </>
                                                            }
                                                        </DataSource>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="formgrid grid">
                                                    <div className="col-12 md:col-3">
                                                        Genero 
                                                    </div>
                                                    <div className="col-12 md:col-9">
                                                        <div className="flex flex-wrap">
                                                            {
                                                            app.menus.genero.map((option) => {
                                                            return (
                                                            <div key={option.value} className="field-radiobutton  mx-3">
                                                                <RadioButton inputId={option.value} name="genero" value={option.value} onChange={formik.handleChange}  checked={formik.values.genero === option.value} className={inputClassName(formik?.errors?.genero, '')} />
                                                                <label htmlFor={option.value}>{option.label}</label>
                                                            </div>
                                                            )
                                                            })
                                                            }
                                                        </div>
                                                        <ErrorMessage name="genero" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        { props.showFooter && 
                                        <div className="text-center my-3">
                                            <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Actualizar" icon="pi pi-send" loading={saving} />
                                        </div>
                                        }
                                    </Form>
                                    );
                                    }
                                    }
                                    </Formik>
                                </div>
                                {
                                (currentRecord && !props.isSubPage) && 
                                <div className="col-12 sm:col-12 lg:col-6 md:col-8">
                                    <div className="card my-3">
                                        <MasterDetailPages masterRecord={currentRecord} scrollIntoView={false} />
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
ClientesEditPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'clientes',
	apiPath: 'clientes/edit',
	routeName: 'clientesedit',
	submitButtonLabel: "Actualizar",
	formValidationError: "El formulario no es válido",
	formValidationMsg: "Por favor complete el formulario",
	msgTitle: "Actualizar registro",
	msgAfterSave: "Registro actualizado con éxito",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default ClientesEditPage;
