import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressSpinner } from 'primereact/progressspinner';
import { RadioButton } from 'primereact/radiobutton';
import { SelectButton } from 'primereact/selectbutton';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useAddPage from 'hooks/useAddPage';
const ConsultasAddPage = (props) => {
		const app = useApp();
	
	//form validation rules
	const validationSchema = yup.object().shape({
		fecha: yup.string().nullable().label("Fecha"),
		tipo_consulta: yup.string().nullable().label("Tipo de Consulta"),
		id_cliente: yup.string().nullable().label("Cliente"),
		proxima_cita: yup.string().nullable().label("Proxima Cita"),
		id_alteraciones: yup.array().nullable().label("Alteraciones En Esta Consulta"),
		motivo: yup.string().nullable().label("Motivo"),
		id_tratante: yup.number().nullable().label("Tratante"),
		diagnostico: yup.string().nullable().label("Diagnostico"),
		tratamiento: yup.string().nullable().label("Tratamiento"),
		alergias: yup.string().nullable().label("Alergias"),
		a_patologicos: yup.string().nullable().label("Antecedentes Patologicos"),
		estado: yup.string().required().label("Estado"),
		usuario_r: yup.string().nullable().label("Usuario R")
	});
	
	//form default values
	const formDefaultValues = {
		fecha: new Date(), 
		tipo_consulta: "1", 
		id_cliente: '', 
		proxima_cita: new Date(), 
		id_alteraciones: [], 
		motivo: '', 
		id_tratante: '', 
		diagnostico: '', 
		tratamiento: '', 
		alergias: '', 
		a_patologicos: '', 
		estado: "1", 
		usuario_r: '', 
	}
	
	//page hook where logics resides
	const pageController =  useAddPage({ props, formDefaultValues, afterSubmit });
	
	// destructure and grab what the page needs
	const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;
	
	//event raised after form submit
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		resetForm();
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/consultas`);
		}
	}
	
	// page loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	
	//page has loaded any required data and ready to render
	if(pageReady){
		return (
<main id="ConsultasAddPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Agregar Nueva Consulta"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={(values, actions) =>submitForm(values)}>
                            {(formik) => 
                            <>
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="md:col-3 col-12">
                                        Fecha 
                                        <div> 
                                            <Calendar name="fecha" value={formik.values.fecha} onChange={formik.handleChange} showButtonBar showTime dateFormat="yy-mm-dd" hourFormat="24"showIcon className={inputClassName(formik?.errors?.fecha)}        />
                                            <ErrorMessage name="fecha" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-7 col-12">
                                        Tipo de Consulta 
                                        <div> 
                                            <DataSource   apiPath="components_data/tipo_consulta_option_list"  >
                                                {
                                                ({ response }) => 
                                                <>
                                                <div className="flex flex-wrap">
                                                    {
                                                    response.map((option) => {
                                                    return (
                                                    <div key={option.value} className="field-radiobutton  mx-3">
                                                        <RadioButton inputId={option.value} name="tipo_consulta" value={option.value} onChange={formik.handleChange}  checked={formik.values.tipo_consulta === option.value} className={inputClassName(formik?.errors?.tipo_consulta, '')} />
                                                        <label htmlFor={option.value}>{option.label}</label>
                                                    </div>
                                                    )
                                                    })
                                                    }
                                                </div>
                                                <ErrorMessage name="tipo_consulta" component="span" className="p-error" />
                                                </>
                                                }
                                            </DataSource>
                                        </div>
                                    </div>
                                    <div className="md:col-7 col-12">
                                        Cliente 
                                        <div> 
                                            <DataSource   apiPath="components_data/id_cliente_option_list"  >
                                                {
                                                ({ response }) => 
                                                <>
                                                <Dropdown  name="id_cliente"     optionLabel="label" optionValue="value" value={formik.values.id_cliente} onChange={formik.handleChange} options={response} label="Cliente"  placeholder="Seleccione un valor" filter={true} className={inputClassName(formik?.errors?.id_cliente)}   />
                                                <ErrorMessage name="id_cliente" component="span" className="p-error" />
                                                </>
                                                }
                                            </DataSource>
                                        </div>
                                    </div>
                                    <div className="md:col-5 col-12">
                                        Proxima Cita 
                                        <div> 
                                            <Calendar name="proxima_cita" showButtonBar className={inputClassName(formik?.errors?.proxima_cita)} dateFormat="yy-mm-dd" value={formik.values.proxima_cita} onChange={formik.handleChange} showIcon        />
                                            <ErrorMessage name="proxima_cita" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-12 col-12">
                                        Alteraciones En Esta Consulta 
                                        <div> 
                                            <div className="flex flex-wrap">
                                                {
                                                app.menus.idAlteraciones.map((option) => {
                                                return (
                                                <div key={option.value} className="field-checkbox mx-3">
                                                    <Checkbox inputId={option.value} name="id_alteraciones" value={option.value} onChange={formik.handleChange} checked={formik.values.id_alteraciones.includes(option.value)} className={inputClassName(formik?.errors?.id_alteraciones, '')} />
                                                    <label htmlFor={option.value}>{option.label}</label>
                                                </div>
                                                )
                                                })
                                                }
                                            </div>
                                            <ErrorMessage name="id_alteraciones" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-8 col-12">
                                        Motivo 
                                        <div> 
                                            <InputTextarea name="motivo"  className={inputClassName(formik?.errors?.motivo)}   value={formik.values.motivo} placeholder="Escribir Motivo" onChange={formik.handleChange}   >
                                            </InputTextarea>
                                            <ErrorMessage name="motivo" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-4 col-12">
                                        Tratante 
                                        <div> 
                                            <InputText name="id_tratante"  onChange={formik.handleChange}  value={formik.values.id_tratante}   label="Tratante" type="number" placeholder="Escribir Tratante"  min={0}  step="any"    className={inputClassName(formik?.errors?.id_tratante)} />
                                            <ErrorMessage name="id_tratante" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-6 col-12">
                                        Diagnostico 
                                        <div> 
                                            <InputTextarea name="diagnostico"  className={inputClassName(formik?.errors?.diagnostico)}   value={formik.values.diagnostico} placeholder="Escribir Diagnostico" onChange={formik.handleChange}   >
                                            </InputTextarea>
                                            <ErrorMessage name="diagnostico" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-6 col-12">
                                        Tratamiento 
                                        <div> 
                                            <InputTextarea name="tratamiento"  className={inputClassName(formik?.errors?.tratamiento)}   value={formik.values.tratamiento} placeholder="Escribir Tratamiento" onChange={formik.handleChange}   >
                                            </InputTextarea>
                                            <ErrorMessage name="tratamiento" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-6 col-12">
                                        Alergias 
                                        <div> 
                                            <InputTextarea name="alergias"  className={inputClassName(formik?.errors?.alergias)}   value={formik.values.alergias} placeholder="Escribir Alergias" onChange={formik.handleChange}   >
                                            </InputTextarea>
                                            <ErrorMessage name="alergias" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-6 col-12">
                                        Antecedentes Patologicos 
                                        <div> 
                                            <InputTextarea name="a_patologicos"  className={inputClassName(formik?.errors?.a_patologicos)}   value={formik.values.a_patologicos} placeholder="Escribir Antecedentes Patologicos" onChange={formik.handleChange}   >
                                            </InputTextarea>
                                            <ErrorMessage name="a_patologicos" component="span" className="p-error" />
                                        </div>
                                    </div>
                                    <div className="md:col-12 col-12">
                                        Estado *
                                        <div> 
                                            <DataSource   apiPath="components_data/estado_option_list_2"  >
                                                {
                                                ({ response }) => 
                                                <>
                                                <SelectButton name="estado" className={inputClassName(formik?.errors?.estado, '')} value={formik.values.estado} onChange={formik.handleChange} options={response} optionLabel="label" optionValue="value" />
                                                <ErrorMessage name="estado" component="span" className="p-error" />
                                                </>
                                                }
                                            </DataSource>
                                        </div>
                                    </div>
                                    <input name="usuario_r"  value={formik.values.usuario_r} type="hidden" />
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label="Entregar" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            </>
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}

//page props and default values
ConsultasAddPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'consultas',
	apiPath: 'consultas/add',
	routeName: 'consultasadd',
	submitButtonLabel: "Entregar",
	formValidationError: "El formulario no es válido",
	formValidationMsg: "Por favor complete el formulario",
	msgTitle: "Crear registro",
	msgAfterSave: "Grabar agregado exitosamente",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default ConsultasAddPage;
