import { useState } from 'react';
import { RecordCount } from 'components/RecordCount';
import { Title } from 'components/Title';

export default function HomePage() {
	
	const [pageReady, setPageReady] = useState(true);
	return (
		<main id="HomePage" className="main-page">
<section className="page-section q-pa-md" >
    <div className="container-fluid">
        <div className="grid ">
            <div className="col comp-grid" >
                <Title title="Home"   titleClass="text-lg font-bold text-primary" subTitleClass="text-500"      separator={false} />
            </div>
            <div className="col-12 md:col-3 comp-grid" >
                <RecordCount title="Clientes" description="Total Clientes" icon="pi pi-users" apiPath="components_data/getcount_clientes" link={`/clientes`} cardClass="bg-blue-100 text-blue-800" avatarClass="bg-blue-600 text-blue-100"   />
            </div>
            <div className="col-12 md:col-3 comp-grid" >
                <RecordCount title="Productos" description="Total Productos" icon="pi pi-box" apiPath="components_data/getcount_productos" link={`/productos`} cardClass="bg-blue-100 text-blue-800" avatarClass="bg-blue-600 text-blue-100"   />
            </div>
            <div className="col-12 md:col-3 comp-grid" >
                <RecordCount title="Consultas" description="Total Consultas" icon="pi pi-box" apiPath="components_data/getcount_consultas" link={`/consultas`} cardClass="bg-blue-100 text-blue-800" avatarClass="bg-blue-600 text-blue-100"   />
            </div>
        </div>
    </div>
</section>
		</main>
	);
}
