import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { DataSource } from 'components/DataSource';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { RadioButton } from 'primereact/radiobutton';
import { SelectButton } from 'primereact/selectbutton';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import useEditPage from 'hooks/useEditPage';
import MasterDetailPages from './MasterDetailPages';
const ConsultasEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		id: yup.number().required().label("Id"),
		fecha: yup.string().nullable().label("Fecha"),
		tipo_consulta: yup.string().nullable().label("Tipo de Consulta"),
		id_cliente: yup.string().nullable().label("Cliente"),
		proxima_cita: yup.string().nullable().label("Proxima Cita"),
		id_alteraciones: yup.array().nullable().label("Alteraciones En Esta Consulta"),
		motivo: yup.string().nullable().label("Motivo"),
		id_tratante: yup.number().nullable().label("Tratante"),
		diagnostico: yup.string().nullable().label("Diagnostico"),
		tratamiento: yup.string().nullable().label("Tratamiento"),
		alergias: yup.string().nullable().label("Alergias"),
		a_patologicos: yup.string().nullable().label("Antecedentes Patologicos"),
		estado: yup.string().required().label("Estado"),
		usuario_r: yup.string().nullable().label("Usuario R")
	});
	// form default values
	const formDefaultValues = {
		id: '', 
		fecha: new Date(), 
		tipo_consulta: "1", 
		id_cliente: '', 
		proxima_cita: new Date(), 
		id_alteraciones: [], 
		motivo: '', 
		id_tratante: '', 
		diagnostico: '', 
		tratamiento: '', 
		alergias: '', 
		a_patologicos: '', 
		estado: "1", 
		usuario_r: '', 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, currentRecord, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/consultas`);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="ConsultasEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container-fluid">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Editar"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container-fluid">
            <div className="grid ">
                <div className="col sm:col-12 lg:col-12 md:col-12 comp-grid" >
                    <div >
                        <div className="grid ">
                            <div className="col sm:col-12 lg:col-6 md:col-5">
                                <Formik
                                    initialValues={formData}
                                    validationSchema={validationSchema} 
                                    onSubmit={(values, actions) => {
                                    submitForm(values);
                                    }
                                    }
                                    >
                                    { (formik) => {
                                    return (
                                    <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                        <div className="grid">
                                            <div className="md:col-2 col-12">
                                                Id *
                                                <div> 
                                                    <InputText name="id"  onChange={formik.handleChange}  value={formik.values.id}   label="Id" type="number" placeholder="Escribir Id"  min={0}  step="any"    className={inputClassName(formik?.errors?.id)} />
                                                    <ErrorMessage name="id" component="span" className="p-error" />
                                                </div>
                                            </div>
                                            <div className="md:col-3 col-12">
                                                Fecha 
                                                <div> 
                                                    <Calendar name="fecha" value={formik.values.fecha} onChange={formik.handleChange} showButtonBar showTime dateFormat="yy-mm-dd" hourFormat="24"showIcon className={inputClassName(formik?.errors?.fecha)}        />
                                                    <ErrorMessage name="fecha" component="span" className="p-error" />
                                                </div>
                                            </div>
                                            <div className="md:col-7 col-12">
                                                Tipo de Consulta 
                                                <div> 
                                                    <DataSource   apiPath="components_data/tipo_consulta_option_list"  >
                                                        {
                                                        ({ response }) => 
                                                        <>
                                                        <div className="flex flex-wrap">
                                                            {
                                                            response.map((option) => {
                                                            return (
                                                            <div key={option.value} className="field-radiobutton  mx-3">
                                                                <RadioButton inputId={option.value} name="tipo_consulta" value={option.value} onChange={formik.handleChange}  checked={formik.values.tipo_consulta === option.value} className={inputClassName(formik?.errors?.tipo_consulta, '')} />
                                                                <label htmlFor={option.value}>{option.label}</label>
                                                            </div>
                                                            )
                                                            })
                                                            }
                                                        </div>
                                                        <ErrorMessage name="tipo_consulta" component="span" className="p-error" />
                                                        </>
                                                        }
                                                    </DataSource>
                                                </div>
                                            </div>
                                            <div className="md:col-7 col-12">
                                                Cliente 
                                                <div> 
                                                    <DataSource   apiPath="components_data/id_cliente_option_list"  >
                                                        {
                                                        ({ response }) => 
                                                        <>
                                                        <Dropdown  name="id_cliente"     optionLabel="label" optionValue="value" value={formik.values.id_cliente} onChange={formik.handleChange} options={response} label="Cliente"  placeholder="Seleccione un valor" filter={true} className={inputClassName(formik?.errors?.id_cliente)}   />
                                                        <ErrorMessage name="id_cliente" component="span" className="p-error" />
                                                        </>
                                                        }
                                                    </DataSource>
                                                </div>
                                            </div>
                                            <div className="md:col-5 col-12">
                                                Proxima Cita 
                                                <div> 
                                                    <Calendar name="proxima_cita" showButtonBar className={inputClassName(formik?.errors?.proxima_cita)} dateFormat="yy-mm-dd" value={formik.values.proxima_cita} onChange={formik.handleChange} showIcon        />
                                                    <ErrorMessage name="proxima_cita" component="span" className="p-error" />
                                                </div>
                                            </div>
                                            <div className="md:col-12 col-12">
                                                Alteraciones En Esta Consulta 
                                                <div> 
                                                    <div className="flex flex-wrap">
                                                        {
                                                        app.menus.idAlteraciones.map((option) => {
                                                        return (
                                                        <div key={option.value} className="field-checkbox mx-3">
                                                            <Checkbox inputId={option.value} name="id_alteraciones" value={option.value} onChange={formik.handleChange} checked={formik.values.id_alteraciones.includes(option.value)} className={inputClassName(formik?.errors?.id_alteraciones, '')} />
                                                            <label htmlFor={option.value}>{option.label}</label>
                                                        </div>
                                                        )
                                                        })
                                                        }
                                                    </div>
                                                    <ErrorMessage name="id_alteraciones" component="span" className="p-error" />
                                                </div>
                                            </div>
                                            <div className="md:col-8 col-12">
                                                Motivo 
                                                <div> 
                                                    <InputTextarea name="motivo"  className={inputClassName(formik?.errors?.motivo)}   value={formik.values.motivo} placeholder="Escribir Motivo" onChange={formik.handleChange}   >
                                                    </InputTextarea>
                                                    <ErrorMessage name="motivo" component="span" className="p-error" />
                                                </div>
                                            </div>
                                            <div className="md:col-4 col-12">
                                                Tratante 
                                                <div> 
                                                    <InputText name="id_tratante"  onChange={formik.handleChange}  value={formik.values.id_tratante}   label="Tratante" type="number" placeholder="Escribir Tratante"  min={0}  step="any"    className={inputClassName(formik?.errors?.id_tratante)} />
                                                    <ErrorMessage name="id_tratante" component="span" className="p-error" />
                                                </div>
                                            </div>
                                            <div className="md:col-6 col-12">
                                                Diagnostico 
                                                <div> 
                                                    <InputTextarea name="diagnostico"  className={inputClassName(formik?.errors?.diagnostico)}   value={formik.values.diagnostico} placeholder="Escribir Diagnostico" onChange={formik.handleChange}   >
                                                    </InputTextarea>
                                                    <ErrorMessage name="diagnostico" component="span" className="p-error" />
                                                </div>
                                            </div>
                                            <div className="md:col-6 col-12">
                                                Tratamiento 
                                                <div> 
                                                    <InputTextarea name="tratamiento"  className={inputClassName(formik?.errors?.tratamiento)}   value={formik.values.tratamiento} placeholder="Escribir Tratamiento" onChange={formik.handleChange}   >
                                                    </InputTextarea>
                                                    <ErrorMessage name="tratamiento" component="span" className="p-error" />
                                                </div>
                                            </div>
                                            <div className="md:col-6 col-12">
                                                Alergias 
                                                <div> 
                                                    <InputTextarea name="alergias"  className={inputClassName(formik?.errors?.alergias)}   value={formik.values.alergias} placeholder="Escribir Alergias" onChange={formik.handleChange}   >
                                                    </InputTextarea>
                                                    <ErrorMessage name="alergias" component="span" className="p-error" />
                                                </div>
                                            </div>
                                            <div className="md:col-6 col-12">
                                                Antecedentes Patologicos 
                                                <div> 
                                                    <InputTextarea name="a_patologicos"  className={inputClassName(formik?.errors?.a_patologicos)}   value={formik.values.a_patologicos} placeholder="Escribir Antecedentes Patologicos" onChange={formik.handleChange}   >
                                                    </InputTextarea>
                                                    <ErrorMessage name="a_patologicos" component="span" className="p-error" />
                                                </div>
                                            </div>
                                            <div className="md:col-12 col-12">
                                                Estado *
                                                <div> 
                                                    <DataSource   apiPath="components_data/estado_option_list_2"  >
                                                        {
                                                        ({ response }) => 
                                                        <>
                                                        <SelectButton name="estado" className={inputClassName(formik?.errors?.estado, '')} value={formik.values.estado} onChange={formik.handleChange} options={response} optionLabel="label" optionValue="value" />
                                                        <ErrorMessage name="estado" component="span" className="p-error" />
                                                        </>
                                                        }
                                                    </DataSource>
                                                </div>
                                            </div>
                                            <input name="usuario_r"  value={formik.values.usuario_r} type="hidden" />
                                        </div>
                                        { props.showFooter && 
                                        <div className="text-center my-3">
                                            <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Actualizar" icon="pi pi-send" loading={saving} />
                                        </div>
                                        }
                                    </Form>
                                    );
                                    }
                                    }
                                    </Formik>
                                </div>
                                {
                                (currentRecord && !props.isSubPage) && 
                                <div className="col sm:col-12 lg:col-6 md:col-7">
                                    <div className="card my-3">
                                        <MasterDetailPages masterRecord={currentRecord} scrollIntoView={false} />
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}
ConsultasEditPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'consultas',
	apiPath: 'consultas/edit',
	routeName: 'consultasedit',
	submitButtonLabel: "Actualizar",
	formValidationError: "El formulario no es válido",
	formValidationMsg: "Por favor complete el formulario",
	msgTitle: "Actualizar registro",
	msgAfterSave: "Registro actualizado con éxito",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default ConsultasEditPage;
